<template>
  <div class="d-flex flex-column align-items-center">
    <div class="app-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="header-container" v-if="!$route.meta.hideHeader">
        <Header/>
      </div>
      <div class="content-container">
        <LoadingOverlay v-if="isLoading"/>
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingOverlay from "@/views/components/LoadingOverlay";
import Header from "@/views/components/Header";
import {mapState} from "vuex";

export default {
  components: {LoadingOverlay, Header},
  computed: {
    ...mapState(['isLoading'])
  },
  data() {
    return {
    }
  },
  watch: {
  },
};
</script>
<style>
.app-container {
  height: 100vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.header-container {
  height: 23%;
  background-image: url("assets/header-banner.png");
  background-size: cover;
}

.content-container {
  position: relative;
  background-color: #FFFFFF;
  background-size: cover;
  z-index: 1;
  height: 75%;
  border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
}
</style>

